import axios from 'axios'
import { $i18n } from './i18n'
import store from '@/store'


import Vue from 'vue'
let rootDomain = location.hostname.split('.').reverse().splice(0,2).reverse().join('.')

const $axios = axios.create({
    baseURL: (process.env.VUE_APP_API_URL || process.env.BASE_URL || 'http://localhost:3000').replace("suyun" + "cdn.com", rootDomain ),
    devURL: process.env.VUE_APP_DEV_URL || process.env.BASE_DEV_URL || 'http://localhost:3000',
    timeout: process.env.NODE_ENV === 'development' ? 60000 : 300000
});

$axios.interceptors.request.use(config => {
    if (!config.url) {
        return config;
    }

    const currentUrl = new URL(config.url, config.dev ? config.devURL : config.baseURL);
    // parse pathName to implement variables
    Object.entries(config.urlParams || {}).forEach(([
        k,
        v,
    ]) => {
        currentUrl.pathname = currentUrl.pathname.replace('{' + k + '}', encodeURIComponent(v)).replace('%7B' + k + '%7D', encodeURIComponent(v));
    });

    // i18n Header
    config.headers['Accept-Language'] = store.state.locale;

    const authPart = currentUrl.username && currentUrl.password ? `${currentUrl.username}:${currentUrl.password}` : '';
    return {
        ...config,
        baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
        url: currentUrl.pathname,
    };
});

function arrayKeysToTranslation(errorData, errors = []) {
  if (Array.isArray(errorData)) {
    errorData.forEach(item => {
      if (item instanceof Object) {
        errors = objectKeysToTranslation(item, errors);
      } else if (item.isArray()) {
        errors = arrayKeysToTranslation(item, errors);
      } else {
        errors.push(item);
      }
    });
  }
  return errors;
}

function objectKeysToTranslation(errorData, errors = []) {
  if (errorData instanceof Object) {
    for (let key in errorData) {
      if (key == "success" && errorData[key] == false) {
        continue;
      }
      const value = errorData[key];
      if (value instanceof Object) {
        errors = objectKeysToTranslation(value, errors);
      } else if (Array.isArray(value)) {
        errors = arrayKeysToTranslation(value, errors);
      } else {
        errors.push(key + "." + value);
      }
    }
  }
  return errors;
}

$axios.interceptors.response.use((response) => {
    return response
},
    (error) => {
        let errors = []
        if (error.response) {
            if (error.response.data instanceof Object) {
              errors = objectKeysToTranslation(error.response.data, errors);
            } else if (error.response.data.message) {
                errors.push(error.response.data.message);
            } else {
                errors.push($i18n('message.request_error'));
            }
        } else {
          errors.push($i18n('message.network_error'));
        }

        return Promise.reject(errors);
    });
export default $axios;
