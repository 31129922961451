import actions from './actions'

const state = {
  checkout: {
    subscriptions: [],
    txn_id: "",
    status: null,
    status_url: "",
    checkout_url: "",
    amount_currency1: null,
    amount_currency2: null,
    currency1: "",
    currency2: "",
    address: "",
    item_name: "",
    type: null,
    payment_type: null,
    user: {
      username: ""
    },
    bill_no: ""
  }
}

export default {
  state,
  actions
}
