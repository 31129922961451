<template>
  <div class="time-range-picker-container">
    <div class="time-range-display" v-if="isCustomPicker">
      {{ customTimeRangeDisplay }}
    </div>
    <div class="time-range-display" v-else>{{ timeRangeDisplay }}</div>

    <CTabs :active-tab="activeTab" @update:activeTab="activeTabChange">
      <CTab :title="$t('time_picker.Relative')" :tabindex="0">
        <div class="adjustment-buttons relative clearfix">
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.MIN_1)"
                   color="primary"
                   id="1_minute_r"
                   @click="relativeAdjust(adjustments.MIN_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.MIN_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.MIN_5)"
                   color="primary"
                   id="5_minute_r"
                   @click="relativeAdjust(adjustments.MIN_5, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.MIN_5]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.MIN_15)"
                   color="primary"
                   id="15_minute_r"
                   @click="relativeAdjust(adjustments.MIN_15, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.MIN_15]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.HOUR_1)"
                   color="primary"
                   id="1_hour_r"
                   @click="relativeAdjust(adjustments.HOUR_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.HOUR_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.HOUR_4)"
                   color="primary"
                   id="4_hour_r"
                   @click="relativeAdjust(adjustments.HOUR_4, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.HOUR_4]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.DAY_1)"
                   color="primary"
                   id="1_day_r"
                   @click="relativeAdjust(adjustments.DAY_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.DAY_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.TODAY)"
                   color="primary"
                   id="today_r"
                   @click="relativeAdjust(adjustments.TODAY, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.TODAY]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.WEEK_1)"
                   color="primary"
                   id="1_week_r"
                   @click="relativeAdjust(adjustments.WEEK_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.WEEK_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.THIS_WEEK)"
                   color="primary"
                   id="this_week_r"
                   @click="relativeAdjust(adjustments.THIS_WEEK, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.THIS_WEEK]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.DAY_30)"
                   color="primary"
                   id="30_day_r"
                   @click="relativeAdjust(adjustments.DAY_30, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.DAY_30]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.RELATIVE, adjustments.THIS_MONTH)"
                   color="primary"
                   id="this_month_r"
                   @click="relativeAdjust(adjustments.THIS_MONTH, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.THIS_MONTH]) }}
          </CButton>
          <CButton variant="ghost" class="custom" id="custom_r" color="primary" @click="showCustomRelative">{{ $t('Custom') }}</CButton>
        </div>
        <div class="custom-picker" v-if="showCustomRelativeAdjust">
          <CInput type="number" id="custom_picker_r" :value="customPickerValue" :min="1" @change="relativeCustomAdjustValueChange"/>
          <CSelect
              @change="relativeCustomAdjustTypeChange"
              :value.sync="customPickerUnit"
              id="custom_picker_unit_r"
              :options="[{value: 'days', label: $t('Days')},{value: 'hours', label: $t('Hours')},{value: 'minutes', label: $t('Minutes')},{value: 'seconds', label: $t('Seconds')}]"
          />
          <CButton color="primary" @click="customAdjustProceed">{{ $t('Okay') }}</CButton>
        </div>
      </CTab>
      <CTab :title="$t('time_picker.TimeFrame')">
        <div class="adjustment-buttons timeframe clearfix">
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.MIN_1)"
                   color="primary"
                   id="1_minute_t"
                   @click="timeFrameAdjust(adjustments.MIN_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.MIN_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.MIN_15)"
                   color="primary"
                   id="15_minute_t"
                   @click="timeFrameAdjust(adjustments.MIN_15, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.MIN_15]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.HOUR_1)"
                   color="primary"
                   id="1_hour_t"
                   @click="timeFrameAdjust(adjustments.HOUR_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.HOUR_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.HOUR_4)"
                   color="primary"
                   id="4_hour_t"
                   @click="timeFrameAdjust(adjustments.HOUR_4, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.HOUR_4]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.DAY_1)"
                   color="primary"
                   id="1_day_t"
                   @click="timeFrameAdjust(adjustments.DAY_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.DAY_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.WEEK_1)"
                   color="primary"
                   id="1_week_t"
                   @click="timeFrameAdjust(adjustments.WEEK_1, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.WEEK_1]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.DAY_30)"
                   color="primary"
                   id="30_day_t"
                   @click="timeFrameAdjust(adjustments.DAY_30, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.DAY_30]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.TODAY)"
                   color="primary"
                   id="today_t"
                   @click="timeFrameAdjust(adjustments.TODAY, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.TODAY]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.YESTERDAY)"
                   color="primary"
                   id="yesterday_t"
                   @click="timeFrameAdjust(adjustments.YESTERDAY, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.YESTERDAY]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.DAY_BEFORE_YESTERDAY)"
                   color="primary"
                   id="day_before_yesterday_t"
                   @click="timeFrameAdjust(adjustments.DAY_BEFORE_YESTERDAY, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.DAY_BEFORE_YESTERDAY]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.THIS_WEEK)"
                   color="primary"
                   id="this_week_t"
                   @click="timeFrameAdjust(adjustments.THIS_WEEK)">
            {{ $t(timeAdjustmentsTranslations[adjustments.THIS_WEEK]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.PREVIOUS_WEEK)"
                   color="primary"
                   id="previous_week_t"
                   @click="timeFrameAdjust(adjustments.PREVIOUS_WEEK, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.PREVIOUS_WEEK]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.THIS_MONTH)"
                   color="primary"
                   id="this_month_t"
                   @click="timeFrameAdjust(adjustments.THIS_MONTH)">
            {{ $t(timeAdjustmentsTranslations[adjustments.THIS_MONTH]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.PREVIOUS_MONTH)"
                   color="primary"
                   id="previous_month_t"
                   @click="timeFrameAdjust(adjustments.PREVIOUS_MONTH)">
            {{ $t(timeAdjustmentsTranslations[adjustments.PREVIOUS_MONTH]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.THIS_QUARTER)"
                   color="primary"
                   id="this_quarter_t"
                   @click="timeFrameAdjust(adjustments.THIS_QUARTER, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.THIS_QUARTER]) }}
          </CButton>
          <CButton :variant="getAdjustmentButtonVariant(adjustmentTypes.TIME_FRAME, adjustments.THIS_YEAR)"
                   color="primary"
                   id="this_year_t"
                   @click="timeFrameAdjust(adjustments.THIS_YEAR, $event)">
            {{ $t(timeAdjustmentsTranslations[adjustments.THIS_YEAR]) }}
          </CButton>
          <CButton variant="ghost" id="frame_custom_t" class="custom_t" color="primary" @click="showCustomTimeFrameAdjust = true">{{ $t('Custom') }}
          </CButton>
        </div>
        <div class="custom-picker" v-if="showCustomTimeFrameAdjust">
          <CInput :value="customPickerValue" id="custom_picker_t" type="number" :min="1" @change="timeFrameCustomAdjustValueChange"/>
          <CSelect
              :value.sync="customPickerUnit"
              @change="timeFrameCustomAdjustTypeChange"
              id="custom_picker_unit_t"
              :options="[{value: 'days', label: $t('Days')},{value: 'hours', label: $t('Hours')},{value: 'minutes', label: $t('Minutes')}]"
          />
          <CButton color="primary" @click="customAdjustProceed">{{ $t('Okay') }}</CButton>
        </div>
      </CTab>
      <CTab :title="$t('Custom')">
        <v-date-picker :value="customTimeRange.start" @input="customStartChange" mode="dateTime" :max-date="new Date()"
                       :is24hr="true">
          <template v-slot="{ inputValue, inputEvents }">
            <CInput
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
                :label="$t('start_date')"
                :value="inputValue"
                id="custom_time_range_start"
                v-on="inputEvents"
            ></CInput>
          </template>
        </v-date-picker>

        <v-date-picker v-model="customTimeRange.end" @input="customEndChange" mode="dateTime" :max-date="new Date()"
                       :is24hr="true">
          <template v-slot="{ inputValue, inputEvents }">
            <CInput
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
                :label="$t('end_date')"
                :value="inputValue"
                id="custom_time_range_end"
                v-on="inputEvents"
            ></CInput>
          </template>
        </v-date-picker>

        <div class="form-row">
          <div class="col-sm-3">&nbsp;</div>
          <div class="col-sm-6">
            <CButton color="primary" @click="customAdjustProceed">{{ $t('Okay') }}</CButton>
          </div>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>

<style scoped>

.custom-picker {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 10px;
}

.custom-picker .form-group {
  margin-bottom: 0;
}

.time-range-display {
  color: gray;
  margin-bottom: 20px;
}

.time-range-picker-container {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 70px;
}

.adjustment-buttons button {
  float: left;
  margin-right: 6px;
  margin-bottom: 6px;
}

.tab-content .tab-pane {
  padding: 10px 0;
}
</style>

<script>
import $ from 'jquery';
import {
  set,
  setDate,
  setHours,
  setMonth,
  setMinutes,
  setSeconds,
  setDay,
  subHours,
  subMinutes,
  subSeconds,
  subMonths,
  subDays,
  addDays,
  addMonths,
  addYears,
  getQuarter, format
} from 'date-fns';

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;
const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7;

export const TIME_ADJUSTMENTS = {
  MIN_1: 1,
  MIN_5: 5,
  MIN_15: 15,
  HOUR_1: MINUTES_IN_HOUR,
  HOUR_4: MINUTES_IN_HOUR * 4,
  DAY_1: MINUTES_IN_DAY,
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  DAY_BEFORE_YESTERDAY: 'DAY_BEFORE_YESTERDAY',
  WEEK_1: MINUTES_IN_WEEK,
  THIS_WEEK: 'THIS_WEEK',
  PREVIOUS_WEEK: 'PREVIOUS_WEEK',
  DAY_30: MINUTES_IN_DAY * 30,
  THIS_MONTH: 'THIS_MONTH',
  PREVIOUS_MONTH: 'PREVIOUS_MONTH',
  THIS_QUARTER: 'THIS_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
  CUSTOM: 'CUSTOM',
};

export const TIME_ADJUSTMENTS_TRANSLATIONS = {
  [TIME_ADJUSTMENTS.MIN_1]: 'time_picker.1 Minute',
  [TIME_ADJUSTMENTS.MIN_5]: 'time_picker.5 Minutes',
  [TIME_ADJUSTMENTS.MIN_15]: 'time_picker.15 Minutes',
  [TIME_ADJUSTMENTS.HOUR_1]: 'time_picker.1 Hour',
  [TIME_ADJUSTMENTS.HOUR_4]: 'time_picker.4 Hours',
  [TIME_ADJUSTMENTS.DAY_1]: 'time_picker.1 Day',
  [TIME_ADJUSTMENTS.TODAY]: 'time_picker.Today',
  [TIME_ADJUSTMENTS.YESTERDAY]: 'time_picker.Yesterday',
  [TIME_ADJUSTMENTS.DAY_BEFORE_YESTERDAY]: 'time_picker.The Day before Yesterday',
  [TIME_ADJUSTMENTS.WEEK_1]: 'time_picker.1 Week',
  [TIME_ADJUSTMENTS.THIS_WEEK]: 'time_picker.This Week',
  [TIME_ADJUSTMENTS.PREVIOUS_WEEK]: 'time_picker.Previous Week',
  [TIME_ADJUSTMENTS.DAY_30]: 'time_picker.30 Days',
  [TIME_ADJUSTMENTS.THIS_MONTH]: 'time_picker.This Month',
  [TIME_ADJUSTMENTS.PREVIOUS_MONTH]: 'time_picker.Previous Month',
  [TIME_ADJUSTMENTS.THIS_QUARTER]: 'time_picker.This Quarter',
  [TIME_ADJUSTMENTS.THIS_YEAR]: 'time_picker.This Year'
};

export const ADJUSTMENT_TYPE = {
  RELATIVE: 'RELATIVE',
  TIME_FRAME: 'TIME_FRAME',
  CUSTOM: 'CUSTOM'
};

export default {
  props: ['onApply', 'limited'],
  data() {
    return {
      activeTab: 0,
      adjustments: Object.freeze(TIME_ADJUSTMENTS),
      adjustmentTypes: Object.freeze(ADJUSTMENT_TYPE),
      timeAdjustmentsTranslations: Object.freeze(TIME_ADJUSTMENTS_TRANSLATIONS),
      lastAdjustmentSelected: [ADJUSTMENT_TYPE.RELATIVE, TIME_ADJUSTMENTS.MIN_15],
      showCustomRelativeAdjust: false,
      showCustomTimeFrameAdjust: false,
      customPickerValue: 15,
      customPickerUnit: 'minutes',
      customTimeRange: {start: subMinutes(new Date(), 15), end: new Date()}
    }
  },
  watch: {
    panelsVisible() {
      if (this.panelsVisible) {
        this.lastAdjustmentSelected = this.timeAdjustment;
      }
    }
  },
  computed: {
    panelsVisible() {
      return this.$parent.$parent.panelsVisible;
    },
    timeRange: {
      get() {
        return this.$store.state.timeRangePicker.range;
      },
      set(value) {
        this.$store.commit('setTimeRange', value);
      }
    },
    timeAdjustment: {
      get() {
        return this.$store.state.timeRangePicker.adjustment;
      },
      set(value) {
        this.$store.state.timeRangePicker.adjustment = value;
      }
    },
    isCustomPicker: function () {
      return this.showCustomRelativeAdjust || this.showCustomTimeFrameAdjust || (this.activeTab === 2);
    },
    timeRangeDisplay: function () {
      if (!this.timeRange) {
        return '';
      }

      const timeFormat = 'MMM d, yyyy, HH:mm:ss';
      return format(this.timeRange.start, timeFormat) + ' ~ ' + format(this.timeRange.end, timeFormat);
    },
    customTimeRangeDisplay: function () {
      if (!this.customTimeRange) {
        return '';
      }

      const timeFormat = 'MMM d, yyyy, HH:mm:ss';
      return format(this.customTimeRange.start, timeFormat) + ' ~ ' + format(this.customTimeRange.end, timeFormat);
    }
  },
  mounted() {
    if (this.panelsVisible) {
      this.lastAdjustmentSelected = this.timeAdjustment;
    }

    if (this.activeTab === 0) {
      this.showCustomRelativeAdjust = false;
    } else if (this.activeTab === 1) {
      this.showCustomTimeFrameAdjust = false;
    }

    if (this.limited) {
      $(document).ready(() => {
        const relative_buttons = $('.relative button.btn-outline-primary');
        let nextButton = relative_buttons.nextUntil('.custom');
        nextButton.slice(2, nextButton.length).hide();
        const timeframe_buttons = $('.timeframe button');
        nextButton = timeframe_buttons.nextUntil('.custom');
        nextButton.slice(3, nextButton.length).hide();
      })
    }
  },
  methods: {
    getAdjustmentButtonVariant(adjustmentType, adjustmentButton) {
      if (this.isAdjustmentButtonActive(adjustmentType, adjustmentButton)) {
        return 'outline';
      }

      return 'ghost';
    },
    isAdjustmentButtonActive(adjustmentType, adjustmentButton) {
      if (!this.lastAdjustmentSelected)
        return false;

      const _adjustmentType = this.lastAdjustmentSelected[0];
      const _adjustmentButton = this.lastAdjustmentSelected[1];

      return (_adjustmentType === adjustmentType) && (_adjustmentButton === adjustmentButton);
    },
    showCustomRelative() {
      this.showCustomRelativeAdjust = true;
      this.relativeCustomAdjust(this.customPickerValue, this.customPickerUnit);
    },
    showCustomTimeFrame() {
      this.showCustomTimeFrameAdjust = true;
      // time frame custom does not second as a picker unit, so force it to minutes.
      if (this.customPickerUnit === 'seconds') {
        this.customPickerUnit = 'minutes';
      }
      this.timeFrameCustomAdjust(this.customPickerValue, this.customPickerUnit);
    },
    async relativeAdjust (adjustment, $event) {
      if (this.limited) {
        const active_button = $($event.target);
        const nextButton = active_button.nextUntil('.custom');
        nextButton.show();
        nextButton.slice(2, nextButton.length).hide();
      }
      this.lastAdjustmentSelected = [ADJUSTMENT_TYPE.RELATIVE, adjustment];

      const endRange = new Date();
      let startRange = subMinutes(new Date(), adjustment);

      const isSimpleSubscriptionOperation = ![this.adjustments.TODAY, this.adjustments.THIS_WEEK, this.adjustments.THIS_MONTH].includes(adjustment);

      if (isSimpleSubscriptionOperation) {
        this.timeRange = {start: startRange, end: endRange};
        this.timeAdjustment = this.lastAdjustmentSelected;
        await this.onApply();
        return;
      }

      startRange = setHours(new Date(), 0);
      startRange = setMinutes(startRange, 0);
      startRange = setSeconds(startRange, 0);

      if (adjustment === this.adjustments.THIS_WEEK) {
        const monday = 1;
        startRange = setDay(startRange, monday);
      } else if (adjustment === this.adjustments.THIS_MONTH) {
        const firstDayOfTheMonth = 1;
        startRange = setDate(startRange, firstDayOfTheMonth);
      }

      this.timeRange = {start: startRange, end: endRange};
      this.timeAdjustment = this.lastAdjustmentSelected;

      await this.onApply();
    },
    async timeFrameAdjust(adjustment, $event) {
      if (this.limited) {
        const active_button = $($event.target);
        const nextButton = active_button.nextUntil('.custom');
        nextButton.show();
        nextButton.slice(2, nextButton.length).hide();
      }

      this.lastAdjustmentSelected = [ADJUSTMENT_TYPE.TIME_FRAME, adjustment];

      let endRange = setSeconds(new Date(), 0);
      let startRange;

      const january = 0;
      const april = 3;
      const july = 6;
      const oct = 9;
      const monday = 1;
      const startOfToday = set(new Date(), {hours: 0, minutes: 0, seconds: 0});
      const firstDateOfCurrentMonth = setDate(startOfToday, 1);
      const yesterday = subDays(startOfToday, 1);

      const thisWeek = setDay(startOfToday, monday);
      switch (adjustment) {
        case this.adjustments.MIN_1:
        case this.adjustments.MIN_15:
          startRange = subMinutes(endRange, adjustment);
          break;
        case this.adjustments.HOUR_1:
        case this.adjustments.HOUR_4:
        case this.adjustments.DAY_1:
          endRange = setMinutes(endRange, 0);
          startRange = subMinutes(endRange, adjustment);
          break;
        case this.adjustments.WEEK_1:
        case this.adjustments.DAY_30:
          endRange = set(endRange, {hours: 0, minutes: 0});
          startRange = subMinutes(endRange, adjustment);
          break;
        case this.adjustments.TODAY:
          startRange = startOfToday;
          endRange = subSeconds(addDays(startRange, 1), 1);
          break;
        case this.adjustments.YESTERDAY:
          startRange = yesterday;
          endRange = subSeconds(startOfToday, 1);
          break;
        case this.adjustments.DAY_BEFORE_YESTERDAY:
          startRange = subDays(yesterday, 1);
          endRange = subSeconds(yesterday, 1);
          break;
        case this.adjustments.THIS_WEEK:
          startRange = thisWeek;
          endRange = subSeconds(addDays(thisWeek, 7), 1);
          break;
        case this.adjustments.PREVIOUS_WEEK:
          startRange = subDays(thisWeek, 7);
          endRange = subSeconds(thisWeek, 1);
          break;
        case this.adjustments.THIS_MONTH:
          startRange = firstDateOfCurrentMonth;
          endRange = subSeconds(addMonths(firstDateOfCurrentMonth, 1), 1);
          break;
        case this.adjustments.PREVIOUS_MONTH:
          startRange = subMonths(firstDateOfCurrentMonth, 1);
          endRange = subSeconds(firstDateOfCurrentMonth, 1);
          break;
        case this.adjustments.THIS_QUARTER:
          const currentQuarter = getQuarter(startOfToday);
          if (currentQuarter === 1)
            startRange = setMonth(firstDateOfCurrentMonth, january);
          else if (currentQuarter === 2)
            startRange = setMonth(firstDateOfCurrentMonth, april);
          else if (currentQuarter === 3)
            startRange = setMonth(firstDateOfCurrentMonth, july);
          else if (currentQuarter === 4)
            startRange = setMonth(firstDateOfCurrentMonth, oct);

          endRange = subSeconds(addMonths(startRange, 3), 1);
          break;
        case this.adjustments.THIS_YEAR:
          startRange = setMonth(firstDateOfCurrentMonth, january);
          endRange = subSeconds(addYears(startRange, 1), 1);
          break;
      }

      this.timeRange = {start: startRange, end: endRange};
      this.timeAdjustment = this.lastAdjustmentSelected;
      await this.onApply();
    },
    async customAdjustProceed() {
      if (this.customTimeRange.start < this.customTimeRange.end) {
        this.timeRange = this.customTimeRange;
        this.timeAdjustment = [ADJUSTMENT_TYPE.CUSTOM, null];
        await this.onApply();
      } else {
        this.flash(this.$t('The end date must be greater than the start date'), 'warning', {timeout: 8000})
      }
    },
    relativeCustomAdjustValueChange(e) {
      this.customPickerValue = Object.freeze(e);
      this.relativeCustomAdjust(Object.freeze(this.customPickerValue), Object.freeze(this.customPickerUnit));
    },
    relativeCustomAdjustTypeChange(e) {
      this.customPickerUnit = Object.freeze(e.target.value);
      this.relativeCustomAdjust(Object.freeze(this.customPickerValue), Object.freeze(this.customPickerUnit));
    },
    relativeCustomAdjust(customPickerValue, customPickerUnit) {
      const endRange = new Date();
      let startRange;

      if (customPickerUnit === 'seconds') {
        startRange = subSeconds(endRange, customPickerValue);
      } else if (customPickerUnit === 'minutes') {
        startRange = subMinutes(endRange, customPickerValue);
      } else if (customPickerUnit === 'hours') {
        startRange = subHours(endRange, customPickerValue);
      } else if (customPickerUnit === 'days') {
        startRange = subDays(endRange, customPickerValue);
      }

      this.customTimeRange.start = startRange;
      this.customTimeRange.end = endRange;
    },
    timeFrameCustomAdjustValueChange(e) {
      this.customPickerValue = e;
      this.timeFrameCustomAdjust(this.customPickerValue, this.customPickerUnit);
    },
    timeFrameCustomAdjustTypeChange(e) {
      this.customPickerUnit = e.target.value;
      this.timeFrameCustomAdjust(this.customPickerValue, this.customPickerUnit);
    },
    timeFrameCustomAdjust(customPickerValue, customPickerUnit) {
      let endRange = setSeconds(new Date(), 0);
      let startRange;

      if (customPickerUnit === 'minutes') {
        startRange = subMinutes(endRange, customPickerValue);
      } else if (this.customPickerUnit === 'hours') {
        endRange = setMinutes(endRange, 0);
        startRange = subHours(endRange, customPickerValue);
      } else if (this.customPickerUnit === 'days') {
        endRange = setHours(setMinutes(endRange, 0), 0);
        startRange = subDays(endRange, customPickerValue);
      }

      this.customTimeRange.start = startRange;
      this.customTimeRange.end = endRange;
    },
    customStartChange(date) {
      this.customTimeRange.start = date;
    },
    customEndChange(date) {
      this.customTimeRange.end = date;
    },
    activeTabChange(tab) {
      this.activeTab = tab;
      if (this.activeTab === 0) {
        this.showCustomRelativeAdjust = false;
      } else if (this.activeTab === 1) {
        this.showCustomTimeFrameAdjust = false;
      }
    }
  }
}
</script>
