import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { dateTimeFormats } from '@/locales/formats/dataTimeFormats'
import Cookies from 'js-cookie'

Vue.use(VueI18n)


export function loadLocaleMessages() {
    const locales = require.context(
        '@/locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}


export function checkDefaultLanguage() {
    let matched = null
    let languages = Object.getOwnPropertyNames(loadLocaleMessages())
    languages.forEach(lang => {
        if (lang === navigator.language) {
            matched = lang
        }
    })
    if (!matched) {
        languages.forEach(lang => {
            let languagePartials = navigator.language.split('-')[0]
            if (lang === languagePartials) {
                matched = lang
            }
        })
    }
    if (!matched) {
        languages.forEach(lang => {
            let languagePartials = navigator.language.split('-')[0]
            if (lang.split('-')[0] === languagePartials) {
                matched = lang
            }
        })
    }
    return matched
}
export const languages = Object.getOwnPropertyNames(loadLocaleMessages())
export const selectedLocale = Cookies.get('locale') || 
    checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'zh-CN'

export const i18n = new VueI18n({
    dateTimeFormats,
    locale: selectedLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh-CN',
    messages: loadLocaleMessages()
})


/**
 * 插件里面使用i18n
 * @param path 引用的路径
 * @returns String 对应译文
 */
export function $i18n(path) {
    let locale = i18n.locale;
    let messages = i18n.messages;
    let lang = messages[locale];
    let getText = (path) => {
        const array = path.split('.');
        let current = lang;
        let value = ''
        for (let i = 0, j = array.length; i < j; i++) {
            current = current[array[i]]
            if(!current){
              break;  
            }
            if(i === j - 1){
                value = current;
            }
        }
        return value || path;
    }
    return getText(path)
}
