import Vue from 'vue'
import Vuex from 'vuex'
import security from './modules/security'
import transaction from './modules/transaction'
import websocket from './modules/websocket'
import { i18n, selectedLocale } from '@/plugins/i18n'
import Cookies from 'js-cookie'
import {ADJUSTMENT_TYPE, TIME_ADJUSTMENTS} from '@/views/domain/charts/TimeRangePicker';
import {
  subMinutes,
} from 'date-fns';

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  locale: selectedLocale,
  auth: {
    authenticated: false
  },
  balance: 0,
  cname: null,
  timeRangePicker: {
    range: {
      start: subMinutes(new Date(), 15),
      end: new Date(),
    },
    adjustment: [ADJUSTMENT_TYPE.RELATIVE, TIME_ADJUSTMENTS.MIN_15]
  }
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  setLocale(state, newLocale) {
    state.locale = newLocale
    i18n.locale = state.locale;
    Cookies.set('locale', state.locale)
  },
  setTimeRange(state, newTimeRange) {
    state.timeRangePicker.range = Object.freeze(newTimeRange);
  },
  resetTimeRangePicker(state) {
    state.timeRangePicker.range = {
      start: subMinutes(new Date(), 15),
      end: new Date(),
    };
    state.timeRangePicker.adjustment = [ADJUSTMENT_TYPE.RELATIVE, TIME_ADJUSTMENTS.MIN_15];
  }
}

const actions = {
  changeLocale({ commit }, newLocale) {
    //i18n.locale = newLocale
    commit('setLocale', newLocale)
  }
}


const getters = {
  authInfo: state => {
    return state.security.auth.tokenParsed;
  },
  language(state) {
    return state.locale
  }
}

const modules = {
  security,
  transaction,
  websocket
}

export default new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: modules
})
