import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import { i18n, $i18n } from './plugins/i18n'
import './plugins/flashmessage'
import './plugins/moment'
import './plugins/promisebtn'
import './plugins/numerals'
import './plugins/driver'
import './plugins/tawk'
import './plugins/calendar'
import './plugins/slideout-panel'
import {Popconfirm} from 'ant-design-vue';
import 'ant-design-vue/lib/popconfirm/style/css';
import {Button} from 'ant-design-vue';
import 'ant-design-vue/lib/dropdown/style/index.css';
import * as Sentry from "@sentry/vue";

Vue.component(Popconfirm.name, Popconfirm);
Vue.component(Button.name, Button);
Vue.component(Button.Group.name, Button.Button);

// not required. Styles for built-in spinner
//import 'vue-promise-btn/dist/vue-promise-btn.css'


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
export const bus = new Vue();


Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: "https://444a5a8f0a45493788f0e337180d5756@sentry.cwcdn.com/2",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /https:\/\/(?:[\w-]+\.)*cwcdn\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  i18n,
  components: {
    App
  },
  mounted() {
    const titleTag = document.querySelector('title');
    if (titleTag) {
      titleTag.text = $i18n('SiteTitle');
    }
  }
})
